//fonts
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300&family=Nunito&family=Orbitron&family=Patrick+Hand&family=Reem+Kufi+Ink&family=Rubik:wght@300&display=swap');
$rubik:'Rubik', sans-serif;
$nameFont: 'Reem Kufi Ink', sans-serif;
$msgFont: 'Kanit', sans-serif;
$nunito: 'Nunito', sans-serif;
$obitron: 'Orbitron', sans-serif;
$patrick: 'Patrick Hand', cursive;

//colors
$orange: #e85d04;
$navy: #13262F;
$ivory: #FFFDED;
$gunmetal: #242F40;
$offwhite: #EDEFFF;
$ivory: #FFFDED;
$grey: #212529;
$slate-grey: #5F758E;
