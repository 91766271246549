@import "__variables";

//breakpoints
$breakpoint-mobile: 900px;

@media (min-width: $breakpoint-mobile){
    #top-landing-panels{
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        min-height: 75vh;
    }
    
    #landing-footer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 25vh;
        background-color: $offwhite;
        font-family: $msgFont;
        font-size: 24px;
        font-weight: 500;
    }
    
    .top-landing-panels{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 75vh;
    }
    
    #landing-name-div{
        min-width: 32vw;
        background-color: $orange;
        font-family: $obitron;
        font-size: 42px;
        // box-shadow: inset -5px -5px 20px black;
    }
    
    #landing-role-div{
        min-width: 68vw;
        background-color: $gunmetal;
        font-family: $obitron;
        font-size: 42px;
        font-weight: 300;
        color: $offwhite;
    }

    #landing-footer{
        font-family: $patrick;
    }
}

@media (max-width: $breakpoint-mobile){
    #landing-name-div{
        min-width: 100%;
        background-color: $orange;
        font-family: $obitron;
        font-size: 32px;
    }

    #landing-name{
        margin: auto;
        padding: 0.5em
    }

    #landing-role-div{
        background-color: $gunmetal;
        font-family: $obitron;
        text-align: right;
        font-size: 26px;
    }

    #landing-role{
        margin: auto;
        color: $offwhite;
        padding: 0.5em;
    }

    #landing-footer{
        font-size: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: $patrick;
        margin: 1% 0% 0% 0%;
    } 
}