@import "/src/sass/_variables";

$breakpoint-mobile: 900px;

@media (min-width: $breakpoint-mobile){
    #about-header{
        display: flex;
        justify-content: flex-start;
    }
    
    #about-header-content{
        background-color: $offwhite;
        border-radius: 15px;
        box-shadow: 1px 1px 10px black;
        margin-right: 1em;
        padding-left: 1em;
        width: 75%;
    }
    
    .about-container{
        display: flex;
        color: black;
        font-family: $nunito;
    }
    
    #name-title-about{
        font-family: $nunito;
        align-items: center;
    }
    
    #content-about{
        // width: 80%;
        font-family: $nunito;
        font-size: 24px;
    }
    
    #about-image{
        margin-left: auto;
        margin-right: 1em;
        height: fit-content;
        width: 25%;
        border-radius: 5px;
        box-shadow: 1px 1px 5px black;
    
        align-self: center;
    }
}

@media (max-width: $breakpoint-mobile){
    .about-container{
        max-width: 90vw;
        color: black;
    }

    #about-header-content{
        background-color: $offwhite;
        padding: 0.25em;
        border-radius: 15px;
        box-shadow: 1px 1px 10px black;
    }

    #about-image{
        height: auto;
        width: 40%;
        border-radius: 15px;
        // box-shadow: 1px 1px 10px black;
    }

    #name-image{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.1em 0.1em 0.1em 1em;
        background-color: $gunmetal;
        color: $offwhite;
        box-shadow: inset 1px 1px 20px black;
        border-radius: 15px;
        font-family: $rubik;
    }
    
    .descriptor{
        margin-top: 0.2em;
        margin-bottom: 0.2em;
    }

    #content-about{
        font-family: $rubik;
    }

    #content-about > p{
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }
}