@import "/src/sass/_variables";

$breakpoint-mobile: 900px;

@media (min-width: $breakpoint-mobile){
    .box {
        cursor: pointer;
        border-radius: 50%;
        box-shadow: inset 1px 1px 30px black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgb(36,47,64);
        background: radial-gradient(circle, rgba(36,47,64,1) 0%, rgba(36,47,64,1) 53%, rgba(6,9,13,1) 100%);
        margin: 1em;
        z-index: 4;
        color: $offwhite;
        position: absolute;
        font-family: $nunito;
        font-size: 18px;
    }

    #title{
        color: $offwhite;
        font-family: $nunito;
    }
    
    .expanded-box{
        margin-left: 0em;
        padding: 0.25em 4em;
        border-radius: 10% / 75%;
        box-shadow: inset -10px -5px 20px black;
        // box-shadow: 10px 1px 20px black;
        background: rgb(36,47,64);
        background: radial-gradient(circle, rgba(36,47,64,1) 0%, rgba(43,53,70,1) 93%, rgba(6,9,13,1) 100%);
    }
    .content-container{
        margin: 1em;
        width: 100%;
        // display: flex;
    }
    
    .expanded-container{
        display: flex;
    }
    
    .close-button{
        border: 5px solid $offwhite; 
        box-shadow: 2px 0px 20px black;
        border-radius: 50%;
        height: 60px;
        width: 68px;
        margin-left: 2em;
        margin-top: 1em; 
        background: #242F40;
        // background: hover ? "black" : "none", 
    }
    
    .close-button:hover{
        cursor: pointer;
        box-shadow: inset 2px 2px 10px black;
        background: #161d27;
    }
    
    .expand{
        cursor: pointer;
        border-radius: 30px / 50px;
        border: none;
        background-color: transparent;
    }
    
    .expand:hover{
        cursor: pointer;
        box-shadow: inset 0px -1px 10px black;
    }
}

@media (max-width: $breakpoint-mobile){
    .box{
        z-index: 4;
        color: $offwhite;
        font-family: $rubik;
        font-size: 20px;
        background: rgb(36,47,64);
        background: radial-gradient(circle, rgba(36,47,64,1) 0%, rgba(43,53,70,1) 93%, rgba(6,9,13,1) 100%);
        box-shadow: inset 1px 1px 30px black;
        font-family: $nunito;
        width: 85%;
        margin: 0em 0.5em 0em 0.5em;
        padding: 1em;
        border-radius: 15px;
    }

    .box-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .expand{
        margin: 0em 1em 0em 0em;
        height: fit-content;
        background-color: transparent;
        border: none;
    }
}