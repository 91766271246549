@import "/src/sass/_variables";

$breakpoint-mobile: 900px;

@media (min-width: $breakpoint-mobile){
    #homescreen-nav{
        display: flex;
        justify-content: space-between;
        background-color: $offwhite;
        box-shadow: 0px 5px 10px $grey;
        position: fixed;
        width: 100%;
        z-index: 10;
        top: 0;
        // display: flex;
        // justify-content: space-around;
        font-family: $nunito;
    }
    
    #homescreen-nav > ul{
        display: flex;
        justify-content: space-between;
        list-style-type: none;
        padding: 0px;
    }
    
    #languages{
        width: 40%;
        margin-left: 5%;
    }
    
    #socials{
        width: 8%;
        margin-right: 5%;
    }
    
    
    .nav-icon:hover{
        cursor: pointer;
    }
}

@media (max-width: $breakpoint-mobile){
    #homescreen-nav{
        display: flex;
        justify-content: space-between;
        background-color: $offwhite;
        box-shadow: 0px 5px 10px $grey;
        position: fixed;
        width: 100%;
        z-index: 10;
        top: 0;
        // display: flex;
        // justify-content: space-around;
        font-family: $nunito;
        padding-right: 1em;
    }
    
    #homescreen-nav > ul{
        display: flex;
        justify-content: space-between;
        list-style-type: none;
        padding: 0px;
    }
    
    #languages{
        width: 40%;
        margin-left: 5%;
    }
    
    #socials{
        width: 8%;
        margin-right: 10%;
    }
    
    
    .nav-icon:hover{
        cursor: pointer;
    }
}